import './Main.css';
import React, { useEffect, useState } from 'react';
import data from "../json/works.json";

export default function Works() {
    
    const [work, setWork] = useState(data.works[0]);

	useEffect(() => {
        createNav();
        getWorks();
	}, []);

    function createNav() {
        const list = ["all"];
        data.works.forEach((e) => {
            e.type.forEach((i) => {
                if (list.includes(i)) return;
                list.push(i);
            })
        });
        list.sort();
        const doc = [];
        list.forEach((e) => {
            const input = document.createElement("input");
            input.type = "radio";
            input.name = "works";
            input.id = `works-${e}`;
            input.value = e;
            input.onchange = () => getWorks(e);
            if (e === "all") input.defaultChecked = true;

            const label = document.createElement("label");
            label.htmlFor = `works-${e}`;
            label.className = "works-type";
            label.innerText = e.toUpperCase();

            doc.push(input, label);
        });

        const nav = document.querySelector("#works-nav");
        nav.replaceChildren(...doc);
    }

    function getWorks(type = "all") {
        const list = [];

        const works = data.works.filter((e) => e.type.includes(type) || type === "all").sort((a, b) => {
            const ad = new Date(a.date);
            const bd = new Date(b.date);
            return bd.getTime() - ad.getTime();
        });
        works.forEach((e) => {
            const img = document.createElement("img");
            img.src = "/image/" + e.thumb;

            const work = document.createElement("div");
            work.className = "work";
            work.dataset.id = e.id;
            work.dataset.type = e.type;
            work.append(img);

            const container = document.createElement("div");
            container.className = "work-wrap";
            container.onclick = () => workSheet._open(e.id);
            container.append(work);

            list.push(container);
        });

        document.querySelector("#works-grid").replaceChildren(...list);
    }

    class workSheet {
        static _open(id) {
            const target = data.works.find((e) => e.id === id);
            setWork(target);
            
            const list = [];
            target.media.forEach((e) => {
                const container = document.createElement("div");
                container.className = "work-media";

                if (e.origin === "file") {
                    const img = document.createElement("img");
                    img.src = "/image/" + e.src;
                    container.classList.add("file");
                    container.append(img);
                }else if (e.origin === "youtube") {
                    const embed = document.createElement("iframe");
                    embed.src = "https://www.youtube.com/embed/" + e.src.slice(-11);
                    embed.frameborder = "0";
                    container.classList.add("youtube");
                    container.append(embed);
                }

                list.push(container);
            });
            document.querySelector("#work-works").replaceChildren(...list);
            workSheet._toggleLines(true);

            const popup = document.querySelector("#work-popup-wrap");
            popup.classList.add("active");
        }
        static _close() {
            const popup = document.querySelector("#work-popup-wrap");
            popup.classList.remove("active");
            setTimeout(() => {
                workSheet._toggleLines();
                const target = document.querySelector("#work-works");
                for (let i = 0; i < target.children.length; i++) {
                    target.removeChild(target.firstChild);
                }
            }, 300);
        }
        static _toggleLines(check = false) {
            document.querySelectorAll("#work-info-header").forEach((e) => e.classList.remove("hidden"));
            document.querySelectorAll("#work-info").forEach((e) => e.classList.add("hidden"));

            if (!check) return;

            const list = [];
            if (work.client.length === 0) list.push("client");
            if (work.collab.length === 0) list.push("collaborator");
            
            list.forEach((e) => {
                document.querySelector(`#work-${e}-header`).classList.add("hidden");
                document.querySelector(`#work-${e}`).classList.add("hidden");
            });
        }
    }
    

    return(
        <div className="main">
            <nav id="works-nav"/>
            <div>
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,200,200,0.7"/>
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,200,200,0.5)"/>
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,200,200,0.3)"/>
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="var(--main-color)"/>
                    </g>
                </svg>
            </div>
            <div id="works-grid"/>
            <div id="work-popup-wrap">
                <div id="work-popup">
                    <header id="work-header">
                        <div id="work-title-wrapper">
                            <h3 id="work-title">{work.title}</h3>
                            <p id="work-subtitle">{work.subtitle}</p>
                        </div>
                        <a id="work-close" onClick={() => workSheet._close()}>×</a>
                    </header>
                    <div id="work-body">
                        <div id="work-works"/>
                        <h2 id="work-name">{work.title}</h2>
                        <p id="work-subname">{work.subtitle}</p>
                        <h4 id="work-date-header" className="work-info-header">DATE</h4>
                        <p id="work-date" className="work-info">{work.date}</p>
                        <h4 id="work-client-header" className="work-info-header">CLIENT</h4>
                        <p id="work-client" className="work-info">{work.client}</p>
                        <h4 id="work-collaborator-header" className="work-info-header">COLLABORATOR</h4>
                        <p id="work-collaborator" className="work-info">{work.collab}</p>
                        <h4 id="work-description-header" className="work-info-header">DESCRIPTION</h4>
                        <p id="work-description" className="work-info" dangerouslySetInnerHTML={{__html : work.desc}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}