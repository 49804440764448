import './Main.css';
import React, { useEffect } from 'react';
import data from "../json/slider.json";

export default function Home() {
  let isMouseOver = false;

	useEffect(() => {
    const container = document.querySelector("#slider-area");
    container.style.setProperty("--slider-number", 3);
    container.onmouseover = () => isMouseOver = true;
    container.onmouseleave = () => isMouseOver = false;
    
    const interval = setInterval(autoSlide, 5000);

    document.querySelector("#slider-prev").onclick = () => moveContainer(-1);
    document.querySelector("#slider-next").onclick = () => moveContainer(1);
    createCells();

    return() => { clearInterval(interval); }
	}, []);

  function autoSlide() {
    if (!isMouseOver) {
      moveContainer(1);
    }
  }

  function createCells() {
    const cells = [];
    const dots = [];

    const images = data.images;
    images.forEach((e, i) => {
      const cell = document.createElement("img");
      cell.className = "cell";
      cell.src = "/image/" + e.src;
      if (e.link.length !== 0) cell.onclick = () => window.open(e.link);
      cell.dataset.order = i + 1;

      cells.push(cell);

      const dot = document.createElement("span");
      dot.className = "dot";
      if (i === 0) dot.classList.add("active");
      dot.dataset.order = i + 1;

      dots.push(dot);
    });

    cells.splice(0, 0, cells[cells.length - 1]);
    cells.splice(cells.length - 1, 1);

    document.querySelector("#container").replaceChildren(...cells);
    document.querySelector("#slider-dots").replaceChildren(...dots);
  }

  function moveContainer(dir) {
    const container = document.querySelector("#container");
    if (container === null) return;
    const pos = dir * (-100 / container.children.length);
    container.style.transitionDuration = "500ms";
    container.style.transform = `translateX(${pos}%)`;
    container.ontransitionend = () => realign(dir);
  }

  function realign(dir) {
    const container = document.querySelector("#container");
    container.removeAttribute('style');
    if (dir === -1) {
      container.prepend(container.lastElementChild);
    }else if (dir === 1) {
      container.appendChild(container.firstElementChild);
    }

    const dots = document.querySelectorAll(".dot");
    dots.forEach((e) => e.classList.remove("active"));
    const target = Array.from(dots).find((e) => e.dataset.order === container.children[1].dataset.order);
    target.classList.add("active");
  }

  return (
    <div className="main">
      <div id="slider-area">
        <a id="slider-prev" className="slider-button"><i className="fa-solid fa-chevron-left"></i></a>
        <div id="container"/>
        <a id="slider-next" className="slider-button"><i className="fa-solid fa-chevron-right"></i></a>
        <div id="slider-dots"/>
      </div>
      <div id="social" className="sub-content">
        <h3 className="main-title">SOCIAL NETWORK</h3>
        <hr className="main-hr"/>
        <div id="social-icons">
          <a id="social-instagram" href="https://www.instagram.com/ellixspace/" className="social-icon" target="_blank"><i className="fa-brands fa-instagram"></i></a>
          <a id="social-x" href="https://twitter.com/ellixspace" className="social-icon" target="_blank"><i className="fa-brands fa-x-twitter"></i></a>
          <a id="social-youtube" href="https://www.youtube.com/@ellixspace" className="social-icon" target="_blank"><i className="fa-brands fa-youtube"></i></a>
          <a id="social-threads" href="https://www.threads.net/@ellixspace" className="social-icon" target="_blank"><i className="fa-brands fa-threads"></i></a>
          <a id="social-twitch" href="https://www.twitch.tv/ellixspace" className="social-icon" target="_blank"><i className="fa-brands fa-twitch"></i></a>
        </div>
      </div>
      <div id="space" className="sub-content">
        <h3 className="main-title">SPACE NETWORK</h3>
        <hr className="main-hr"/>
        <div id="space-icons">
          <div className="space-icon">
            <div id="space-space" className="space-title">
              <div className="space-img">
                <img src="/image/pink gemstone.svg"/>
              </div>
              <div className="space-text">
                <span>ELLIX</span>
                <h4>SPACE</h4>
              </div>
            </div>
            <span className="space-tooltip">NOW HERE!</span>
          </div>
          <div className="space-icon">
            <div id="space-planet" className="space-title">
              <div className="space-img">
                <img src="/image/ELLIX PLANET LOGO.svg"/>
              </div>
              <div className="space-text">
                <span>ELLIX</span>
                <span>GAMING CLUB</span>
                <h4>PLANET</h4>
              </div>
            </div>
            <span className="space-tooltip">COMING SOON!</span>
          </div>
          <div className="space-icon">
            <div id="space-umia" className="space-title">
              <div className="space-img">
                <img src="/image/UMIA LOGO.svg"/>
              </div>
              <div className="space-text">
                <span>ELLIX</span>
                <span>CREATOR GROUP</span>
                <h4>UMIA</h4>
              </div>
            </div>
            <span className="space-tooltip">COMING SOON!</span>
          </div>
          <div className="space-icon">
            <div id="space-beaters" className="space-title">
              <div className="space-img">
                <img src="/image/BEATERS LOGO.svg"/>
              </div>
              <div className="space-text">
                <span>ELLIX <strong>PLANET</strong></span>
                <span>RHYTHM CLUB</span>
                <img src="/image/BEATERS TEXT.svg"/>
              </div>
            </div>
            <span className="space-tooltip">COMING SOON!</span>
          </div>
          <div className="space-icon">
            <div id="space-mahjong" className="space-title">
              <div className="space-img">
                <img src="/image/애리익수장.svg"/>
              </div>
              <div className="space-text">
                <span>ELLIX <strong>PLANET</strong></span>
                <span>MAHJONG CLUB</span>
                <img src="/image/애리익수장 글씨.svg"/>
              </div>
            </div>
            <span className="space-tooltip">COMING SOON!</span>
          </div>
        </div>
      </div>
    </div>
  );
}